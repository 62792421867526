export type Monsido = {
  _monsido: {
    token: string;
    statistics: {
      enabled: boolean;
      cookieLessTracking: boolean;
      documentTracking: {
        enabled: boolean;
        documentCls: string;
        documentIgnoreCls: string;
        documentExt: string[];
      };
    };
    heatmap: {
      enabled: boolean;
    };
  };
};

declare global {
  interface Window {
    _monsido: Monsido;
  }
}

if (process.env.REACT_APP_MONSIDO_ENABLED === 'true') {
  window._monsido = window._monsido || {
    token: process.env.REACT_APP_MONSIDO_TOKEN_INTERNAL,
    statistics: {
      enabled: true,
      cookieLessTracking: true,
      documentTracking: {
        enabled: false,
        documentCls: 'monsido_download',
        documentIgnoreCls: 'monsido_ignore_download',
        documentExt: ['pdf', 'doc', 'ppt', 'docx', 'pptx'],
      },
    },
    heatmap: {
      enabled: process.env.REACT_APP_MONSIDO_HEATMAPS_ENABLED === 'true',
    },
  };
}
