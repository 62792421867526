import {
  SampleCollectionDTO,
  SampleTypeDTO,
  useGetSampleTypes,
} from '@tsp/api-hooks';
import { contextNotInitialized } from '@tsp/shared-utils';
import { createContext, ReactNode } from 'react';
import { User } from 'src/contexts/session-context';

export type EnrichedSampleCollection = SampleCollectionDTO & {
  sampleTypeName: SampleTypeDTO['name'] | undefined;
};

interface IAppContext {
  sampleTypes?: Readonly<Array<SampleTypeDTO>>;
  refetchSampleTypes: () => void;
}

export const AppContext = createContext<IAppContext>({
  refetchSampleTypes: contextNotInitialized,
});

const ProviderWhenAuthenticated: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { sampleTypes, refetch: refetchSampleTypes } = useGetSampleTypes();

  return (
    <AppContext.Provider
      value={{
        sampleTypes,
        refetchSampleTypes,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

interface AppContextProviderProps {
  currentUser: User | undefined;
}

export const AppContextProvider: React.FC<
  AppContextProviderProps & { children: ReactNode }
> = ({ currentUser, children, ...props }) =>
  currentUser ? (
    <ProviderWhenAuthenticated {...props}>{children}</ProviderWhenAuthenticated>
  ) : (
    <>{children}</>
  );
