import { useCallback, useEffect } from 'react';
import { SampleCollectionDTO, SampleCollectionFilterDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useGetSampleCollections = (filter: SampleCollectionFilterDTO) => {
  const { dispatch, result } = useApiWrapper<
    Array<SampleCollectionDTO>,
    'sampleCollections'
  >('POST:/api/SampleCollection/GetSampleCollections', 'sampleCollections');

  const dispatchWrapper = useCallback(
    (refetchFilter: SampleCollectionFilterDTO = filter) => {
      dispatch({ data: refetchFilter });
    },
    [dispatch, filter]
  );

  useEffect(() => {
    dispatchWrapper();
  }, [dispatchWrapper]);

  return { ...result, refetch: dispatchWrapper };
};
