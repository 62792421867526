import { createSessionContext } from '@innoit/auth-service';
import { AppLocale, fallbackLanguage } from 'src/i18n';

export enum UserRole {
  Admin = 'Admin',
  Customer = 'Customer',
  Internal = 'User',
}

export interface User {
  id: string;
  name: string;
  roles: Array<UserRole>;
  language: AppLocale;
  authorized: boolean;
}

const claims = {
  id: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
  name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  roles: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  language: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality',
};

const { SessionContext, SessionConsumer, SessionProvider } =
  createSessionContext(claims, (data): User => {
    const roles = data.roles
      ? Array.isArray(data.roles)
        ? data.roles
        : [data.roles]
      : [];
    return {
      id: `${data.id}`,
      name: `${data.name}`,
      roles: roles.filter((role: string) =>
        Object.values(UserRole).includes(role as UserRole)
      ),
      language: data.language
        ? (`${data.language}`.replace('_', '-') as AppLocale)
        : fallbackLanguage,
      authorized: roles.some((role) =>
        [UserRole.Admin, UserRole.Internal].includes(role)
      ),
    };
  });

export { SessionContext, SessionConsumer, SessionProvider };
