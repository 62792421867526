import { convertEnumToObject } from './convert-enum-to-object';
import { reverseObject } from './reverse-object';

const isStrEnum = <K extends string>(
  enumObj: Record<K, string> | Record<K, number>
): enumObj is Record<K, string> =>
  Object.values(enumObj).every((val) => typeof val === 'string');

export function convertEnumToReverseObject<K extends string>(
  enumObj: Record<K, string> | Record<K, number>
): Record<string, K> {
  const int = isStrEnum(enumObj)
    ? convertEnumToObject(enumObj)
    : convertEnumToObject(enumObj);
  return reverseObject(int as Record<K, string | number>);
}
