/**
 * Omit keys from a given object
 * @param object a js object
 * @param keysToOmit a list of object keys to be removed
 */
export function omit<R, K extends keyof R, K1 extends K>(
  object: R,
  ...keysToOmit: Array<K1>
): Omit<R, K1>;

/**
 * Omit values from a given array
 * @param object a js Array
 * @param valuesToOmit a list of values to be removed from the array
 */
export function omit<V = unknown>(
  object: Array<V>,
  ...valuesToOmit: Array<V>
): Array<V>;

export function omit<R, K extends keyof R, K1 extends K, V = unknown>(
  object: R | Array<V>,
  ...keysOrValuesToOmit: Array<K1> | Array<V>
): Omit<R, K1> | Array<V> {
  if (Array.isArray(object)) {
    const valuesToOmit = keysOrValuesToOmit as Array<V>;
    return object.filter((value) => !valuesToOmit.includes(value));
  }
  const res = {} as Omit<R, K1>;

  for (const _key in object) {
    const keysToOmit = keysOrValuesToOmit as Array<K1>;
    const key = _key as keyof R;
    if (isKeyToPick(keysToOmit, key)) {
      res[key] = object[key];
    }
  }
  return res;
}

const isKeyToPick = <K, K1 extends K>(
  keysToOmit: Array<K1>,
  key: K
): key is Exclude<K, K1> => !keysToOmit.includes(key as K1);
