const convertNumEnumToObject = <K extends string, V extends number>(
  enumObj: Record<K, V>
): Record<K, V> =>
  Object.entries<V>(enumObj).reduce(
    (prev, [key, val]) =>
      typeof val === 'number' ? { ...prev, [key as K]: val } : prev,
    {} as Record<K, V>
  );

const convertStrEnumToObject = <K extends string, V extends string>(
  enumObj: Record<K, V>
): Record<K, V> => {
  const added = new Set<string>();
  return Object.entries<V>(enumObj).reduce((prev, [key, val]) => {
    if (added.has(val)) {
      return prev;
    }
    added.add(key);
    return { ...prev, [key as K]: val };
  }, {} as Record<K, V>);
};

const isStrEnum = <K extends string>(
  enumObj: Record<K, string> | Record<K, number>
): enumObj is Record<K, string> =>
  Object.values(enumObj).every((val) => typeof val === 'string');

export function convertEnumToObject<K extends string, V extends string>(
  enumObj: Record<K, V>
): Record<K, V>;

export function convertEnumToObject<K extends string, V extends number>(
  enumObj: Record<K, V>
): Record<K, V>;

export function convertEnumToObject<K extends string>(
  enumObj: Record<K, string> | Record<K, number>
): Record<K, string | number> {
  return isStrEnum(enumObj)
    ? convertStrEnumToObject(enumObj)
    : convertNumEnumToObject(enumObj);
}
