import { useCallback, useEffect } from 'react';
import { CaseDTO, SampleDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useGetSamplesByCollectionId = (collectionId?: CaseDTO['id']) => {
  const { dispatch, result } = useApiWrapper<Array<SampleDTO>, 'samples'>(
    'GET:/api/Sample/GetSamplesByCollectionId',
    'samples'
  );

  const dispatchWrapper = useCallback(() => {
    collectionId && dispatch({ params: { collectionId } });
  }, [collectionId, dispatch]);

  useEffect(() => {
    dispatchWrapper();
  }, [dispatchWrapper]);

  return { ...result, refetch: dispatchWrapper };
};
