export const reverseObject = <
  K extends string | number,
  V extends string | number
>(
  obj: Record<K, V>
): Record<V, K> => {
  return Object.entries<V>(obj).reduce(
    (prev, [key, val]) => ({ ...prev, [val]: key }),
    {} as Record<V, K>
  );
};
