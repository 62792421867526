/**
 * Pick keys from a given object
 * @param object a js object
 * @param keysToPick a list of object keys to be picked
 * @returns an object made only of the picked keys and their corresponding values
 */
export function pick<V = unknown>(
  array: Array<V>,
  ...valuesToPick: Array<V>
): Array<V>;

/**
 * Pick keys from a given object
 * @param object a js object
 * @param keysToPick a list of object keys to be picked
 * @returns an object made only of the picked keys and their corresponding values
 */
export function pick<R, K extends keyof R, K1 extends K>(
  obj: R,
  ...keysToPick: Array<K1>
): Pick<R, K1>;

export function pick<R, K extends keyof R, K1 extends K, V = unknown>(
  arrayOrObj: R | Array<V>,
  ...keysOrValuesToPick: Array<K1> | Array<V>
): Pick<R, K1> | Array<V> {
  if (Array.isArray(arrayOrObj)) {
    const valuesToPick = keysOrValuesToPick as Array<V>;
    return arrayOrObj.filter((val) =>
      isKeyToPick(valuesToPick, val)
    ) as Array<V>;
  }

  const obj = arrayOrObj;
  const keysToPick = keysOrValuesToPick as Array<K1>;
  const res = {} as Pick<R, K1>;

  for (const key in obj) {
    if (isKeyToPick(keysToPick, key)) {
      res[key] = obj[key];
    }
  }
  return res;
}

const isKeyToPick = <K>(keysToPick: Array<K>, key: unknown): key is K =>
  keysToPick.includes(key as K);
