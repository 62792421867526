import { useCallback } from 'react';
import { EditSampleCollectionDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useUpdateSampleCollectionStatusById = () => {
  const { dispatch, result } = useApiWrapper<string, 'statusMsg'>(
    'PUT:/api/SampleCollection/UpdateSamplesCollectionStatus',
    'statusMsg'
  );

  const dispatchWrapper = useCallback(
    (data: EditSampleCollectionDTO) => {
      dispatch({ data });
    },
    [dispatch]
  );

  return { dispatch: dispatchWrapper, result };
};
